export const utilsMixin = {
  methods: {
    generateUsername({
      primer_nombre: pn,
      segundo_nombre: sn,
      apellido_paterno: ap,
      apellido_materno: am,
      fecha_nacimiento: fena
    }) {
      const snUser = sn !== null && sn.length > 0 ? sn[0] : ''
      const amUser = am !== null && am.length > 0 ? am[0] : ''
      const splitedFena = fena.split('-')
      const numericUser = `${splitedFena[2]}${splitedFena[1]}`
      const userName = `${pn}${snUser}${ap}${amUser}${numericUser}`
      const userNameClean = userName.replace(/ /g, '').trim()
      return userNameClean
    },
    generatePassword({ primer_nombre: pn, fecha_nacimiento: fena }) {
      const splitedFena = fena.split('-')
      const userPassword = `${pn}${splitedFena.join('')}`
      const userPasswordClean = userPassword.replace(/ /g, '').trim()
      return userPasswordClean
    },
    generateGenericUsername(
      {
        firstName: fn,
        lastName: ln,
      }, type = ''
    ){
      const splitedFn = fn.split(' ')

      const now = new Date()
      const timeInMili = now.getTime()
      const timeString = timeInMili.toString()
      const numericUser = timeString.substring(8, timeString.length)

      const fnUser = fn !== null && splitedFn.length > 0 ? splitedFn[0] : type
      const lnUser = ln !== null && ln.length > 0 ? ln[0] : ''

      const userName = `${fnUser}${lnUser}${numericUser}`
      const userNameClean = userName.replace(/ /g, '').trim()
      return userNameClean
    },
    generateGenericPassword() {
      const userPasswordClean = 'kN_98765'
      return userPasswordClean
    },
    calculateAge(birthday) {
      if (birthday === null) {
        return ''
      }
      const date = new Date(birthday)
      const today = new Date()
      const age = today.getFullYear() - date.getFullYear()
      return age
    },
    fullAddress({calle: ca,
      numero_exterior: ne,
      numero_interior: ni,
      colonia: col,
      ciudad: ci,
      estado: es,
      codigo_postal: cp
    }) {
      const fullAddress = `${ca} ${ne}${ni ? ' '+ni : ''}, Col. ${col}, ${ci}, ${es}. CP. ${cp.codigo_postal}`
      return fullAddress
    },
    fullName({
      primer_nombre: pn,
      segundo_nombre: sn,
      apellido_paterno: ap,
      apellido_materno: am
    }) {
      return `${pn} ${sn} ${ap} ${am}`
    },
  }
}